import React from "react";
import css from "../styles/Products.module.css";
import { useGlobalContext } from "../context/Context";
import { useState } from "react";
import {AiFillCaretLeft, AiFillCaretRight} from "react-icons/ai";

const Products = () => {
	const { items, cart, setCart, quantity, setQuantity, setLength} = useGlobalContext();
	

	let initialDisplayQty = []
	items.forEach(() => {
		initialDisplayQty.push(1);
	});

	let [displayQuantity, setDisplayQuantity] = useState(initialDisplayQty);


	const decreaseQty = (item) => {
		let currentValue = displayQuantity[item.id];
		if(currentValue > 1){
			currentValue--;
		}
		let newDisplayQuantity = [...displayQuantity]
		newDisplayQuantity[item.id] = currentValue;
		setDisplayQuantity(newDisplayQuantity)
	}


	const increaseQty = (item) => {
		let newDisplayQuantity = [...displayQuantity];
		newDisplayQuantity[item.id] += 1;
		setDisplayQuantity(newDisplayQuantity);
	  };


	const handleAddItem = (current) => {
		let flag = false;
		cart &&
			cart.forEach((item) => {
				if (item.id === current.id) {
					flag = true;
				}
			});
		if (!flag) {
			let newCart = [...cart, current];
			setCart(newCart);
			let newQuantity = [...quantity];
			newQuantity[current.id] += displayQuantity[current.id];
			setLength((prev) => prev + displayQuantity[current.id]);
			setQuantity(newQuantity);
		} else {
			let newQuantity = [...quantity];
			newQuantity[current.id] += displayQuantity[current.id];
			setLength((prev) => prev + displayQuantity[current.id]);
			setQuantity(newQuantity);
		}
	};

	const List = ({ items }) => {
		return (
		  items.map(item => {
				return (
						<div className={css.item} key={item.id}>
							<div className={css.left}>
								<img src={item.img} alt={item.title} />
							</div>
							<div className={css.mid}>
								<div className={css.title}>{item.title}</div>
								<div className={css.desc}>{item.desc}</div>
							</div>
							<div className={css.quantity}>
								<AiFillCaretLeft className={css.caret}
									onClick={() => decreaseQty(item)}
								/>
								<div className={css.qtyamt}>{displayQuantity[item.id]}</div>
								<AiFillCaretRight className={css.caret}
								onClick={() => increaseQty(item)}
								/>
							</div>
							<div className={css.priceSection}>
									<div className={css.price}>${item.price}</div>
									<button
										className={css.btn}
										onClick={(e) => {
											e.preventDefault();
											handleAddItem(item);
										}}
									>
									Add To Cart
									</button>
							</div>
						</div>
					)
			}
		  ))
	  };

	return (
		(<div className={css.body}>
			<div className={css.container}>
			<List items={items} />
			</div>
		</div>)
	);
};

export default Products;
