import React from 'react'
import css from "../styles/Footer.module.css";
import {FaFacebookSquare, FaInstagramSquare, FaTwitterSquare, FaLinkedin} from "react-icons/fa";
import { useGlobalContext } from '../context/Context';

const Footer = () => {
  const {showOverlay} = useGlobalContext();
  return (
    (!showOverlay)  && 
    <div className={css.bgc}>
    <div className={css.container}>
      <div className={css.socials}>
        <FaFacebookSquare className={css.social}/>
        <FaInstagramSquare className={css.social}/>
        <FaTwitterSquare className={css.social}/>
        <FaLinkedin className={css.social}/>
      </div>
      <div className={css.copyright}>
      ©2023 All Rights Reserved
      </div>
    </div>
    </div>
  )
}

export default Footer
