import React from 'react'
import { useState, useContext } from 'react';
import {data} from "../data/data.js";

const AppContext = React.createContext();

const AppProvider = ({children}) => {
    const [user, setUser] = useState("");
    const [password, setPassword] = useState();
    const [items, setItems] = useState(data);
    const [cart, setCart] = useState([]);
    const [length, setLength] = useState(0);
    const [selectedOption, setselectedOption] = useState("1");
    const [showOverlay, setShowOverlay] = useState(false);
    let initialQuantity = []
    data.forEach((item) => initialQuantity[item.id] = 0);
    const [quantity, setQuantity] = useState(initialQuantity)
    const [isRunned, setIsRunned] = useState(false)
    return <AppContext.Provider value = {{user, setUser, password, setPassword, items, setItems, cart, setCart, selectedOption, setselectedOption, quantity, setQuantity, length, setLength, showOverlay, setShowOverlay, isRunned, setIsRunned}}>
        {children}
    </AppContext.Provider>
}

export const useGlobalContext = () => {
    return useContext(AppContext);
}

export {AppContext, AppProvider};