import React from "react";
import css from "../styles/Login.module.css";
import { FaUserLock } from "react-icons/fa";
import { useGlobalContext } from "../context/Context";
import { useState } from "react";
import "react-toastify/dist/ReactToastify.css";
import { ImSad, ImBlocked } from "react-icons/im";

const Login = () => {
	const { setUser, setPassword, setShowOverlay } =
		useGlobalContext();
	const [errorMsg, setErrorMsg] = useState("");
	const [currUser, setCurrUser] = useState("auth_user");
	const [currPass, setCurrPass] = useState("auth_password");
	const handleSubmit = (e) => {
		e.preventDefault();
		if (currUser === "auth_user" && currPass === "auth_password") {
			setUser(currUser);
			setPassword(currPass);
			setShowOverlay(false);
			console.log(currUser + " " + currPass);
		} else if (currUser === "banned_user" && currPass === "auth_password") {
			setErrorMsg("Sorry, you've been blocked");
		} else {
			setErrorMsg("Invalid username or password!");
		}
	};

	const toggleModal = () => {
		setShowOverlay(false);
	};
	const doNothing = (e) => {
		e.preventDefault();
    	e.stopPropagation();
	}
	return (
		<div className={css.modal}>
			<div onClick={toggleModal} className={css.overlay}>
				<div className={css.body} onClick={doNothing}>
					<div className={css.container}>
						<div className={css.left}>
						<h2 className={css.loginHeading}>Login</h2>
							{errorMsg !== "" && (
								<div className={css.error}>
									<ImSad className={css.errorLogo} />
									<span className={css.errorSpan}>{errorMsg}</span>
								</div>
							)}
							<form>
								<div className={css.inputContainer}>
									<label htmlFor="uname">Username </label>
									<input
										type="text"
										name="uname"
										id="uname"
										value={currUser}
										autoComplete="off"
										onChange={(e) => {
											setErrorMsg("");
											setCurrUser(e.target.value);
										}}
									/>
								</div>
								<div className={css.inputContainer}>
									<label htmlFor="pass">Password </label>
									<input
										type="password"
										name="pass"
										id="pass"
										value={currPass}
										autoComplete="off"
										onChange={(e) => {
											setErrorMsg("");
											setCurrPass(e.target.value);
										}}
									/>
								</div>
								<button onClick={handleSubmit} className={css.btn}>
									Login
								</button>
							</form>
						</div>
						<div className={css.mid}></div>
						<div className={css.right}>
							<button className={css.closeModal} onClick={toggleModal}>
								X
							</button>
							<h2 className={css.credHeading}>Credentials</h2>
							<div className={css.rightItem}>
								<FaUserLock className={css.icon} />
								<div className={css.details}>
									<h5>Authorized User</h5>
									<span>Username: auth_user</span>
									<span>Password: auth_password</span>
								</div>
							</div>
							<div className={css.rightItem}>
								<ImBlocked className={css.icon} />
								<div className={css.details}>
									<h5>Banned User</h5>
									<span>Username: banned_user</span>
									<span>Password: banned_password</span>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Login;
