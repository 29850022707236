import React, { useEffect } from "react";

function Advertisement() {
  useEffect(() => {
    
    const vitag = window.vitag || {};
    const viAPItag = window.viAPItag || {};

    vitag.Init = vitag.Init || [];
    vitag.Init.push(function () {
      viAPItag.display("vi_1267110146");
    });
  }, []);

  return (
    <div
      className="adsbyvli"
      data-ad-slot="vi_1267110146"
      data-full-width-responsive="true"
      style={{ width: 728, height: 90, paddingTop: "39px", margin: "0 auto" }}
    ></div>
  );
}

export default Advertisement;
