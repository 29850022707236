export const data = [
	{
		id: 0,
		img: "https://cdn.britannica.com/21/182021-050-666DB6B1/book-cover-To-Kill-a-Mockingbird-many-1961.jpg",
		title: "To Kill a Mockingbird",
		desc: "To Kill a Mockingbird is a novel about racial injustice in 1930s Alabama, seen through the eyes of Scout Finch, as her father Atticus defends a wrongly accused black man.",
		price: 50,
	},
	{
		id: 1,
		img: "https://m.media-amazon.com/images/I/A1aDb5U5myL.jpg",
		title: "War and Peace",
		desc: "War and Peace is a novel about the Napoleonic era in Russia, following five aristocratic families and its impact on society, leading up to Napoleon's invasion.",
		price: 30,
	},
	{
		id: 2,
		img: "https://upload.wikimedia.org/wikipedia/commons/thumb/7/7a/The_Great_Gatsby_Cover_1925_Retouched.jpg/800px-The_Great_Gatsby_Cover_1925_Retouched.jpg",
		title: "The Great Gatsby",
		desc: '"The Great Gatsby" is a novel set in 1920s America after WWI, depicting the prosperity and decadence of the "Jazz Age" with Prohibition and rise of organized crime.',
		price: 40,
	},
	{
		id: 3,
		img: "https://m.media-amazon.com/images/I/51l4CWBf1YL._SL500_.jpg",
		title: "The Adventures of Huckleberry Finn",
		desc: 'It is a classic American novel about a child-hero who travels on a journey filled with personal growth and discovery, exploring themes of liberty and the complexities of life.',
		price: 37,
	},
	{
		id: 4,
		img: "https://m.media-amazon.com/images/I/71BgRhNF4lL.jpg",
		title: "In Search of Lost Time",
		desc: '"Swann Way" is the first part of Prousts seven-part cycle "In Search of Lost Time." It introduces themes, such as memory and love, and is considered an unparalleled work that provides a literary equivalent to modern physics.',
		price: 70,
	},
	{
		id: 5,
		img: "https://i0.wp.com/literariness.org/wp-content/uploads/2019/03/41JKGW9P6AL._SX372_BO1204203200_.jpg",
		title: "Don Quixote",
		desc: '"Don Quixote" is a novel about a retired gentleman who becomes obsessed with chivalry books and believes in their truth, causing others to see him as insane. He sets out on adventures with his squire, Sancho Panza.',
		price: 60,
	},
	{
		id: 6,
		img: "https://kbimages1-a.akamaihd.net/c626c0f3-a624-460e-ba7d-b2bd35f9c8c7/1200/1200/False/think-and-grow-rich-154.jpg",
		title: "Think and Grow Rich",
		desc: "'Think and Grow Rich is the result of Hill's study of over five hundred self-made millionaires—a condensed, accessible explanation of his Law of Success philosophy, which includes thirteen steps to riches.'",
		price: 100,
	},
	{
		id: 7,
		img: "https://m.media-amazon.com/images/I/61HvcPo9seL.jpg",
		title: "As a Man Thinketh",
		desc: "As A Man Thinketh is an essay and self-help classic, which argues that the key to mastering your life is harnessing the power of your thoughts and helps you cultivate the philosophy and attitude of a positive, successful person.",
		price: 25,
	},
	{
		id: 8,
		img: "https://m.media-amazon.com/images/I/51htpzlPNQL.jpg",
		title: "Choose Yourself",
		desc: "Choose Yourself is a call to give up traditional career paths and take your life into your own hands by building good habits, creating your own career, and making a decision to choose yourself.",
		price: 64,
	},
	{
		id: 9,
		img: "https://m.media-amazon.com/images/I/81hHy5XrdKL.jpg",
		title: "The Four Agreements",
		desc: "The Four Agreements draws on the long tradition of the Toltecs, an ancient, indigenous people of Mexico, to show you that we have been domesticated from childhood, guiding rules hurt us and what we can do to break and replace them.",
		price: 49,
	},
	{
		id: 10,
		img: "https://m.media-amazon.com/images/I/51Z0nLAfLmL.jpg",
		title: "The Alchemist",
		desc: "The Alchemist is a classic novel in which a boy embarks on a journey seeking treasure and on the way meets mentors, falls in love, and most importantly, learns the true importance of who he is and how to improve himself",
		price: 80,
	},
	{
		id: 11,
		img: "https://literatureglobe.com/wp-content/uploads/2022/01/Power-of-Positive-Thinking-min.jpg",
		title: "The Power of Positive Thinking",
		desc: "The Power Of Positive Thinking will show you that the roots of success lie in the mind and teach you how to believe in yourself and take control of your life by taking control of your thoughts and changing your attitude.",
		price: 15,
	},

];
