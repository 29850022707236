import React from 'react'
import { useGlobalContext } from '../context/Context'
import { useState } from 'react';
import { useEffect } from 'react';
import css from "../styles/Total.module.css";
import { useNavigate } from 'react-router-dom';

const Total = () => {
    const navigate = useNavigate();

    const {user, cart, quantity, setShowOverlay} = useGlobalContext();

    const [total, setTotal] = useState(0);

    useEffect(() => {
        let newTotal = 0;
        cart.forEach((item) => newTotal += quantity[item.id] * item.price);
        setTotal(newTotal);
    }, [cart, quantity]);

    const handleCheckout = () => {
      navigate("/checkout");
    }
    
    const handleLogin = (e) => {
      e.preventDefault();
      setShowOverlay(true);
    }

  return (
    <div className = {css.body}>
      <span>Total : ${total}</span>
      {user === "" ? <button className={css.btn} onClick={handleLogin}>Login</button> :
      <button className={css.btn} onClick={handleCheckout}>Checkout</button>
      }
    </div>
  )
}

export default Total
