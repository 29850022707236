import React from "react";
import { Navigate } from "react-router-dom";
import { useGlobalContext } from "../context/Context";
const ProtectedRoute = ({ children }) => {
	const { user, password } = useGlobalContext();
    if(!user || !password){
        return <Navigate to="/" />;
    }
	return children;
};

export default ProtectedRoute;
