import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { FaShoppingCart } from "react-icons/fa";
import { useGlobalContext } from "../context/Context";
import { useNavigate } from "react-router-dom";
import { HiOutlineLogout, HiOutlineLogin } from "react-icons/hi";
import Login from "../pages/Login";
import Advertisement from "./Advertisement"; 
import css from "../styles/Header.module.css";

const Header = ({ location }) => {
  const {
    user,
    items,
    setItems,
    setUser,
    setPassword,
    selectedOption,
    setselectedOption,
    length,
    showOverlay,
    setShowOverlay,
    isRunned,
    setIsRunned,
  } = useGlobalContext();

  useEffect(() => {
    const initialTask = () => {
      if (isRunned) return;
      setIsRunned(true);
      setShowOverlay(true);
    };
    initialTask();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const navigate = useNavigate();

  const handleLogout = (e) => {
    e.preventDefault();
    setUser("");
    setPassword("");
  };

  const handleLogin = (e) => {
    e.preventDefault();
    setShowOverlay(true);
  };

  const handleCartClick = (e) => {
    e.preventDefault();
    navigate("/cart");
  };

  const handleSort = (e) => {
    e.preventDefault();
    const chosen = e.target.value;
    setselectedOption(chosen);
    console.log(chosen);
    if (chosen === "1") {
      let sortedItems = items.sort((i1, i2) =>
        i1.title > i2.title ? 1 : i1.title < i2.title ? -1 : 0
      );
      setItems(sortedItems);
    } else if (chosen === "2") {
      let sortedItems = items.sort((i1, i2) =>
        i1.title < i2.title ? 1 : i1.title > i2.title ? -1 : 0
      );
      setItems(sortedItems);
    } else if (chosen === "3") {
      let sortedItems = items.sort((i1, i2) =>
        i1.price > i2.price ? 1 : i1.price < i2.price ? -1 : 0
      );
      setItems(sortedItems);
    } else {
      let sortedItems = items.sort((i1, i2) =>
        i1.price < i2.price ? 1 : i1.price > i2.price ? -1 : 0
      );
      setItems(sortedItems);
    }
  };

  return (
    <>
      {showOverlay && <Login />}
      <div className={css.header}>
        <div className={css.container}>
          <div className={css.logo}>
            <Link to="/" className={css.logoLink}>
              <h1 className={css.aot}>ArtOfTesting Ecom</h1>
            </Link>
          </div>
          <div className={css.right}>
            <div className={css.detailCart}>
              <FaShoppingCart
                className={css.cart}
                onClick={handleCartClick}
              />
              <span className={css.myCart}>{length}</span>
            </div>
            {user === "" ? (
              <button
                className={`${css.button} ${css.btn}`}
                onClick={handleLogin}
                type="button"
              >
                <div className={css.logout}>
                  <span>Login</span>
                  <HiOutlineLogin />
                </div>
              </button>
            ) : (
              <button
                className={`${css.button} ${css.btn}`}
                onClick={handleLogout}
                type="button"
              >
                <div className={css.logout}>
                  <span>Logout</span>
                  <HiOutlineLogout />
                </div>
              </button>
            )}
          </div>
        </div>
      </div>
	  <Advertisement /> 
      <div className={css.bar}>
        <div className={css.barContainer}>
          {location === "All Products" && (
            <div className={css.sort}>
              <select
                value={selectedOption}
                onChange={handleSort}
                className={css.select}
              >
                <option value="1">Name (A to Z)</option>
                <option value="2">Name (Z to A)</option>
                <option value="3">Price (Low to High)</option>
                <option value="4">Price (High to Low)</option>
              </select>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Header;
